//  Constructor
function Tablet(apiBase) {
	this.apiBase = apiBase;
	this.currentContent = "home";
	this.lastUpdate = -1;
	this.lastUpdateActive = -1;
	this.currentEmail = "";
	this.numActiveParticipants = 0;

	this.rewardFactorHack = 2;

	this.editPassword = "0000";

	// Get data from API
	this.refreshData();

	// Refresh data interval
	// this.interval = setInterval($.proxy(this.refreshData, this, true), 400);

	$("#add-form").submit($.proxy(function (e) {
		e.preventDefault();

		console.log($("#add-form input#putting").val());

		// validation
		// Name and university are required. At least one check box must be checked
		if ($('#add-form input#name').val() == "") {
			// no university specified
			$('#add-form input#name').focus();
			return;
		} else if ($('#add-form select#university').val() <= 0 || $('#add-form select#university').val() == "add") {
			// no university specified
			$('#add-form select#university').focus();
			return;
		}
		else if (!$("#add-form input#putting").is(":checked") && !$("#add-form input#hole-in-one").is(":checked") && !$("#add-form input#question").is(":checked")) {
			// no action specified, there needs to be at least one
			alert("You need to select at least one action.");
			return;
		}

		this.addSubmit($("#add-form").serialize());
	}, this));

	$("body").on('change', ".universities .choice", $.proxy(function (e) {
		//var id = $(e.currentTarget).data("id");
		//this.gotoComplete(id);
		$(".universities .choice").removeClass("selected");
		$(e.currentTarget).addClass("selected");
		//console.log("yeah");
	}, this));


	$(".cancel-btn").click($.proxy(function (e) {
		var cancel = confirm("Are you sure you want to reset the form? All progression will be lost.");

		if (cancel) {
			this.clearForm();
		}
	}, this));

	$("#refresh-btn").click($.proxy(function (e) {
		e.preventDefault();
		window.location.href = window.location.href;
	}, this));


	// Fast doesn't seem to work well on ios11, need to double click on labels to trigger
	// FastClick.attach(document.body);
}

/*
 Refresh data through API
 */

Tablet.prototype.refreshData = function (onlyWaiting) {

	if (!onlyWaiting) {
		$.ajax({
			dataType: "json",
			url: this.apiBase + "universities",
			//async: false,
			success: $.proxy(this.refreshDataUniversitiesCallback, this)
		});
	}
}

Tablet.prototype.refreshDataUniversitiesCallback = function (data) {

	// Clear items
	$(".universities select").empty();

	var $el = $('<option value="0" selected> </option>');
	$(".universities select").append($el);

	var $el = $('<option value="add">-- Add a new university --</option>');
	$(".universities select").append($el);

	for (var i = 0; i < data.content.length; i++) {
		var item = data.content[i];

		var $el = $('<option value="' + item.id + '">' + item.name + '</option>');
		$(".universities select").append($el);
	}

	$(".universities select").off("change").on("change", $.proxy(function (e) {
		var that = e.currentTarget;
		if ($(that).val() == "add") {

			// $(that).focusOut();
			setTimeout($.proxy(function () {
				var name = prompt("Enter university's name");

				console.log(name);

				if (name != null && name != "") {

					var query = {
						name: name
					};

					$.ajax({
						dataType: "json",
						url: this.apiBase + "add-university",
						type: "POST",
						data: query,
						success: $.proxy(function () {
							alert("University was added successfully");
							this.refreshData();
						}, this)
					});

				}

				$(".universities select").val(0);
			}, this), 100);

		}
	}, this));

}

Tablet.prototype.addSubmit = function (data) {
	// Disable input
	$('#add-form input[type="submit"]').prop("disabled");
	$('input, select').blur();

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "add",
		type: "POST",
		data: data,

		//async: false,
		success: $.proxy(function () {
			alert("Participant added successfully.");
			this.clearForm();
		}, this)
	});

}

Tablet.prototype.clearForm = function (data) {

	$('input[type="text"]').val("");
	$('input[type="checkbox"]').attr('checked', false); // Unchecks it
	$('select').val(0);

	this.refreshData();
}